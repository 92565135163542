'use client';

import {useEffect} from 'react';

import {isIOS15or16} from './getAppMetadata';

/**
 * @description This component is used to set the viewport meta tag
 * for `iPhone iOS v.15/v.16` devices preventing zoom behavior on
 * input:focus and maintaining compatibility across devices.
 * @return {null}
 */
const ViewportIOSDevices = () => {
  useEffect(() => {
    if (isIOS15or16(navigator.userAgent)) {
      const viewportMeta: HTMLMetaElement | null = document.querySelector('meta[name="viewport"]');
      if (viewportMeta) {
        viewportMeta.content =
          'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no';
      }
    }
  }, []);

  return null;
};

export default ViewportIOSDevices;
